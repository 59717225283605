<template>
  <v-app>
    <div>
      <!-- <v-layout row wrap style="height:100vh">
        <v-flex xs12 lg12 >   -->
       <v-overlay :value="loaddataprogress" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 1" step="1">
            ตรวจสอบ
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">
            ลงทะเบียน One platform
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            เสร็จสิ้นการลงทะเบียน
          </v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>
        <v-stepper-items
          :style="
            this.$vuetify.theme.dark
              ? 'background: #363636'
              : 'background: #E3F2FD; background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);'
          "
          id="thiscontainer_register_mohpromt"
        >
          <v-stepper-content step="1">
            <v-layout row wrap align-center justify-center>
              <v-flex xs12 lg7 md8 sm10>
                <!-- <v-card              
                max-width="800"
                height="300px">
                  <v-card-text class="pa-0" >
                    <v-form v-model="valid" class="ma-2 mt-2 mr-4 ml-4" >
                      <v-container fluid>
                        <v-layout row wrap justify-cener >
                          <v-flex xs12 sm12 md12 lg12>
                            <v-card-text style="font-size: 18px">ตรวจสอบ ID card<br>
                              <p style="font-size: 14px">(Check ID Card)</p>
                            </v-card-text>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg6>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-4 pb-0"
                              v-model="idcard"
                              label="เลขประจำตัวประชาชน"
                              prepend-icon="credit_card"
                      
                            ></v-text-field>
                         
                          </v-flex>
                          <v-flex xs6 sm3 md3 lg3>
                            <v-btn block rounded color="primary" class="ma-4" @click="fn_checkid">ตรวจสอบ</v-btn>
                        </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
        
                </v-card> -->
                <v-card class="mx-auto" max-width="800" height="500px">
                  <v-row class="mb-6" justify="center" no-gutters>
                    <v-col
                      cols="12"
                      md="8"
                      xs="12"
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <v-row class="fill-height" justify="center">
                        <v-col cols="12">
                          <v-container grids-list-xs style="color:#E3F2FD">
                            <img
                              v-if="resolutionScreen >= 400"
                              src="@/assets/OneBox_Mohpromt3.png"
                              width="350px"
                            />
                            <img
                              v-else
                              src="@/assets/OneBox_Mohpromt3.png"
                              width="  200px"
                            />
                          </v-container>
                        </v-col>
                        <v-col class="col-md-12 col-sm-10">
                          <h2
                            class="mt-1 mb-10"
                            style="font-size:20px;text-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;"
                          >
                            ตรวจสอบเลขบัตรประจำตัวประชาชน
                          </h2>

                          <div>
                            <!-- label="รหัสบัตรประชาชน / IDCard" -->
                            <v-flex xs10 sm11 md12 lg12>
                              <v-text-field
                                clearable
                                solo
                                outlined
                                dense
                                class="pa-1 pt-5 pb-0"
                                v-model="id_card_num"
                                :label="$t('landingpage.idcard')"
                                maxlength="13"
                                prepend-icon="credit_card"
                                :rules="requiredidcard"
                                :error-messages="id_card_numError"
                                required
                                @input="$v.id_card_num.$touch()"
                                @blur="$v.id_card_num.$touch()"
                              ></v-text-field>
                            </v-flex>

                            <v-flex xs10 sm8 md6 lg6>
                              <v-row>
                              <v-col>
                                <v-btn
                                  @click="fn_goback"
                                  rounded
                                  block
                                  outlined
                                >
                                  ย้อนกลับ
                                </v-btn>
                              </v-col>
                              <v-col>
                                <v-btn
                                  @click="fn_checkid"
                                  rounded
                                  block
                                  :style="
                                    darkMode +
                                      'font-size: 16px; font-weight: 600; background-color: #F4DA5B; text-shadow: rgba(0, 0, 0, 0.25);'
                                  "
                                >
                                  <!-- ลงทะเบียน -->
                                  {{ $t("landingpage.verify") }}
                                </v-btn>
                              </v-col>
                            </v-row>
                            </v-flex>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-layout row wrap align-center justify-center>
              <v-flex xs12 lg7>
                <v-card>
                  <v-card-text class="pa-0 darkmode">
                    <v-form v-model="valid" class="ma-4 mr-4 ml-4">
                      <v-container fluid>
                        <v-layout row wrap justify-center>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-card-text style="font-size: 20px"
                              >ลงทะเบียนผู้ใช้งาน One Platform<br />
                              <p style="font-size: 17px">
                                (Register Account One Platform)
                              </p>
                            </v-card-text>
                            <v-divider></v-divider>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg4 class="mt-2">
                            <v-select
                              outlined
                              dense
                              clearable
                              class="pa-1 pt-4 pb-0"
                              v-model="typename_th"
                              :items="listtypenameth"
                              item-value="data_type"
                              item-text="data_type"
                              label="คำนำหน้าชื่อ (ไทย) / Title Name (TH)"
                              prepend-icon="mdi-account-circle"
                              :rules="[rules.noSpecialChar]"
                              :error-messages="typename_thError"
                              required
                              @input="
                                fn_checktypenameth(), $v.typename_th.$touch()
                              "
                              @blur="$v.typename_th.$touch()"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg4 class="mt-2">
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-4 pb-0"
                              v-model="firstnameth"
                              label="ชื่อ (ไทย) / First Name (TH)"
                              prepend-icon="mdi-account-circle"
                              :rules="requiredname"
                              :error-messages="firstnamethError"
                              required
                              @change="$v.firstnameth.$touch()"
                              @blur="$v.firstnameth.$touch()"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg4 class="mt-2">
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-4 pb-0"
                              v-model="lastnameth"
                              label="นามสกุล (ไทย) / Last Name (TH)"
                              prepend-icon="mdi-account-circle"
                              :rules="requiredname"
                              :error-messages="lastnamethError"
                              required
                              @input="$v.lastnameth.$touch()"
                              @blur="$v.lastnameth.$touch()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap justify-center class="mt-2">
                          <v-flex xs12 sm12 md12 lg4>
                            <v-select
                              outlined
                              dense
                              clearable
                              v-model="typename_eng"
                              :items="listtypenameeng"
                              item-value="data_type"
                              item-text="data_type"
                              label="คำนำหน้าชื่อ (อังกฤษ) / Title Name (Eng)"
                              prepend-icon="mdi-account-circle"
                              :rules="[rules.noSpecialChar]"
                              :error-messages="typename_engError"
                              required
                              @input="
                                fn_checktypenameeng(), $v.typename_eng.$touch()
                              "
                              @blur="$v.typename_eng.$touch()"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg4>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="firstnameeng"
                              label="ชื่อ (อังกฤษ) / First Name (Eng)"
                              prepend-icon="mdi-account-circle"
                              :rules="requirednameeng"
                              :error-messages="firstnameengError"
                              required
                              @input="$v.firstnameeng.$touch()"
                              @blur="$v.firstnameeng.$touch()"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg4>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="lastnameeng"
                              label="นามสกุล (อังกฤษ) / Last Name (Eng)"
                              prepend-icon="mdi-account-circle"
                              :rules="requirednameeng"
                              :error-messages="lastnameengError"
                              required
                              @input="$v.lastnameeng.$touch()"
                              @blur="$v.lastnameeng.$touch()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap justify-left class="mt-2">
                          <v-flex xs12 sm12 md12 lg4>
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="date"
                                  outlined
                                  dense
                                  label="วันเกิด / Date of Birth"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                                <!-- required
                                :rules="[() => !!date || 'กรุณากรอกข้อมูล']"
                                :error-messages="dateError"
                                @change="$v.date.$touch()"
                                @blur="$v.date.$touch()" -->
                              </template>
                              <v-date-picker
                                ref="picker"
                                v-model="date"
                                :max="new Date().toISOString().substr(0, 10)"
                                min="1950-01-01"
                                @change="save"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <v-flex xs12 sm6 md6 lg4>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="typecrad_num"
                              label="รหัสบัตร 13 หลัก / Identification Number"
                              maxlength="13"
                              prepend-icon="credit_card"
                              :rules="requiredidcard"
                              :error-messages="typecrad_numError"
                              required
                              @input="$v.typecrad_num.$touch()"
                              @blur="$v.typecrad_num.$touch()"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6 lg4>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="phonenumber"
                              label="เบอร์โทรศัพท์ / Mobile No."
                              maxlength="10"
                              prepend-icon="phone"
                              :rules="requiredTextphone"
                              required
                              :error-messages="phonenumberError"
                              @input="$v.phonenumber.$touch()"
                              @blur="$v.phonenumber.$touch()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap justify-left class="mt-2">
                          <v-flex xs12 sm6 md6 lg6>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              v-model="email"
                              label="อีเมล / E-mail"
                              prepend-icon="email"
                              :rules="requiredemail"
                              required
                              :error-messages="emailError"
                              @input="$v.email.$touch()"
                              @blur="$v.email.$touch()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap justify-center class="mt-2">
                          <v-flex xs12 sm4 md4 lg4>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="username"
                              label="ชื่อผู้ใช้ / Username"
                              prepend-icon="people"
                              :rules="requiredusername"
                              required
                              :error-messages="usernameError"
                              @input="fn_tolowercase(), $v.username.$touch()"
                              @blur="$v.username.$touch()"
                              hint="ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ / Must have at least 6 characters"
                              persistent-hint
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="password"
                              label="รหัสผ่าน / Password"
                              prepend-icon="vpn_key"
                              @click:append="showpassword = !showpassword"
                              :type="showpassword ? 'text' : 'password'"
                              :append-icon="
                                showpassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :rules="requiredpassword"
                              :error-messages="passwordError"
                              required
                              @input="$v.password.$touch()"
                              @blur="$v.password.$touch()"
                              hint="ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters"
                              persistent-hint
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm4 md4 lg4>
                            <v-text-field
                              clearable
                              outlined
                              dense
                              class="pa-1 pt-0 pb-0"
                              v-model="confirmpassword"
                              label="ยืนยันรหัสผ่าน / ConfirmPassword"
                              prepend-icon="vpn_key"
                              @click:append="showpassword = !showpassword"
                              :type="showpassword ? 'text' : 'password'"
                              :append-icon="
                                showpassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :rules="requiredconfirmpassword"
                              :error-messages="confirmpasswordError"
                              required
                              @input="$v.confirmpassword.$touch()"
                              @blur="$v.confirmpassword.$touch()"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <!-- <v-layout row wrap justify-center>
                          <v-checkbox v-model="check_accept" color="primary" class="text-center">
                            <template v-slot:label>
                              <v-card-text class="pa-0 pt-4" @click.stop="">
                                ยอมรับ
                                <a @click.prevent="open()">ข้อกำหนดการใช้บริการ ONE ID</a>
                                และ
                                <a @click.prevent="opendialogpolicyoneid = true">นโยบายความคุ้มครองข้อมูลส่วนบุคคล</a><br>
                                <p>(Accept Terms Of Service ONE ID And Privacy Policy)</p>
                              </v-card-text>
                            </template>
                          </v-checkbox>
                      </v-layout> -->
                        <v-divider></v-divider>
                        <v-layout row wrap justify-center class="mt-2">
                          <v-flex class="text-center">
                            <v-btn
                              large
                              color="primary"
                              outlined
                              :disabled="createprogress"
                              @click="(e1 = 1), (id_card_num = '')"
                              >ย้อนกลับ<br />(BACK)</v-btn
                            >
                            <v-btn
                              large
                              class="ml-2"
                              color="primary"
                              :loading="createprogress"
                              @click="fn_checksent_register()"
                              >ลงทะเบียน<br />(Register)</v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-layout row wrap align-center justify-center>
              <v-flex xs12 lg12>
                <v-card class="mx-auto" max-width="800" height="550px">
                  <v-card-text>
                    <v-form v-model="valid">
                      <v-container fluid>
                        <v-layout row wrap justify-center>
                          <v-flex xs12 sm12 md12 lg12 class="text-center">
                            <v-icon size="200px" color="primary"
                              >mdi-check-circle-outline</v-icon
                            >
                            <br /><br />
                            <h1>ลงทะเบียนสำเร็จ</h1>
                            <br />
                            <h3 style="color:#00C853;">
                              (Register One Platform Successfully)
                            </h3>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap justify-center>
                          <v-flex
                            v-if="resolutionScreen >= 400"
                            xs5
                            sm5
                            md5
                            lg5
                          >
                            <v-card
                              lass="mx-auto"
                              max-width="500"
                              max-height="200"
                              elevation="0"
                            >
                              <v-card-text>
                                <p style="text-align: center;">
                                  <strong>ข้อมูล</strong>
                                </p>
                                <p style="text-align: center; color:red;">
                                  โปรดจำข้อมูลนี้ !!
                                </p>
                                <p
                                  style="padding-left: 50px; text-align: left;"
                                >
                                  <strong>username :</strong> {{ username }}
                                </p>
                                <p
                                  style="padding-left: 50px; text-align: left;"
                                >
                                  <strong>อีเมล :</strong> {{ email }}
                                </p>
                                <p
                                  style="padding-left: 50px; text-align: left;"
                                >
                                  <strong>เบอร์โทร :</strong> {{ phonenumber }}
                                </p>
                              </v-card-text>
                              <br />
                            </v-card>
                          </v-flex>
                          <v-flex v-else xs10 sm10 md10 lg10>
                            <br />
                            <p style="text-align: center;">
                              <strong>ข้อมูล</strong>
                            </p>
                            <p style="text-align: center; color:red;">
                              โปรดจำข้อมูลนี้ !!
                            </p>
                            <p style="padding-left: 50px; text-align: left;">
                              <strong>username :</strong> {{ username }}
                            </p>
                            <p style="padding-left: 50px; text-align: left;">
                              <strong>อีเมล :</strong> {{ email }}
                            </p>
                            <p style="padding-left: 50px; text-align: left;">
                              <strong>เบอร์โทร :</strong> {{ phonenumber }}
                            </p>
                            <br />
                          </v-flex>
                        </v-layout>
                        <!-- <v-layout row wrap justify-center class="mt-10">
                          <v-flex class="text-center">
                            <v-btn
                              large
                              color="primary"
                              outlined
                              @click="$router.push('/')"
                              >กลับหน้าหลัก<br />(HOME)</v-btn
                            >
                            <v-btn
                              large
                              class="ml-2"
                              color="primary"
                              @click="$router.push('/loginbysso')"
                              >เข้าสู่ระบบ<br />(Log in)</v-btn
                            >
                          </v-flex>
                        </v-layout> -->
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
                <!-- <v-card v-else>
                  <v-card-text>
                    <v-form v-model="valid">
                      <v-container fluid>
                        <v-layout row wrap justify-center>
                          <v-flex xs12 sm12 md12 lg12 class="text-center">
                            <v-icon size="256px" color="primary"
                              >mdi-check-circle-outline</v-icon
                            >
                            <br />
                            <br />
                            <br />
                            <h1 style="font-size: 25px;">
                              ลงทะเบียนบัญชีนิติบุคคลสำเร็จ
                            </h1>
                            <br />
                            <h3 style="color:#00C853;">
                              (Register Business Successfully)
                            </h3>
                            <br />
                            <p
                              class="font-weight-regular"
                              style="font-size: 16px;"
                            >
                              ระบบใช้เวลาดำเนินการยืนยันเอกสารภายใน 2 วันทำการ
                              หากดำเนินการเรียบร้อยแล้วจะแจ้งกลับทาง email
                            </p>
                            <p
                              class="font-weight-regular"
                              style="font-size: 16px;"
                            >
                              หากมีข้อสงสัยหรือพบปัญหา สามารถติดต่อได้ที่
                              <a>onebox@inet.co.th</a>
                            </p>
                            <br />
                          </v-flex>
                        </v-layout>
             
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card> -->
              </v-flex>
            </v-layout>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <dialogcheckidmohpromt
        :show="opendialogcheckidcardmohpromt"
        @closedialog="opendialogcheckidcardmohpromt = false"
        @nextstep="e1 = 2"
        @haveoneid="fn_gotologin(), (opendialogcheckidcardmohpromt = false)"
      ></dialogcheckidmohpromt>
      <dialogcheckconsentmohpromt
        :show="opendialogcheckconsentcardmohpromt"
        @closedialog="opendialogcheckconsentcardmohpromt = false"
        @agreeconsentgetdata="fn_log_consent()"
      ></dialogcheckconsentmohpromt>
      <dialogcheckconsentmohpromt_register
        :show="opendialogcheckconsentcardmohpromt_register"
        @closedialog="opendialogcheckconsentcardmohpromt_register = false"
        @register="fn_register()"
      ></dialogcheckconsentmohpromt_register>
      <!-- </v-flex>
      </v-layout> -->
    </div>
  </v-app>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import VueCookies from "vue-cookies";
const dialogcheckidmohpromt = () =>
  import("../components/optional/dialog-check-idcard-mohpromt.vue");
const dialogcheckconsentmohpromt = () =>
  import("../components/optional/dialog-consent-mohpromt.vue");
const dialogcheckconsentmohpromt_register = () =>
  import("../components/optional/dialog-consent-register-mohpromt.vue");
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import axios from "axios";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    typename_th: { required },
    firstnameth: { required },
    lastnameth: { required },
    typename_eng: { required },
    firstnameeng: { required },
    lastnameeng: { required },
    // typecrad: { required },
    typecrad_num: { required },
    email: { required },
    username: { required },
    confirmpassword: { required },
    password: { required },
    phonenumber: { required },
    id_card_num:{ required }
    // date: { required },
  },
  components: {
    dialogcheckidmohpromt,
    dialogcheckconsentmohpromt,
    dialogcheckconsentmohpromt_register,
  },
  data: function() {
    return {
      loaddataprogress:false,
      opendialogcheckconsentcardmohpromt_register: false,
      opendialogcheckconsentcardmohpromt: false,
      opendialogcheckidcardmohpromt: false,
      e1: 1,
      idcard: "",
      valid: "",
      typename_th: "",
      listtypenameth: ["นาย", "นาง", "นางสาว"],
      listtypenameeng: ["Mr", "Mrs", "Miss"],
      listtypecrad: "บัตรประชาชน (ID Card)",
      firstnameth: "",
      lastnameth: "",
      typename_eng: "",
      firstnameeng: "",
      lastnameeng: "",
      typecrad: "",
      typecrad_num: "",
      email: "",
      phonenumber: "",
      username: "",
      password: "",
      confirmpassword: "",
      id_card_num: "",
      showpassword: false,
      createprogress: false,
      rules: {
        noSpecialChar: (value) => !!value || "โปรดระบุข้อมูล.",
      },
      required_: [(value) => !!value || "โปรดระบุข้อมูล."],
      requiredTextphone: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length >= 10 && this.fn_checkphonenumber(value)) ||
          "โปรดระบุตัวเลข 10 หลัก / Please fill 10 digits",
      ],
      requiredidcard: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (value && value.length >= 13 && this.fn_checkidcard(value)) ||
          "โปรดระบุตัวเลข 13 หลัก / Please fill 13 digits",
      ],
      requiredemail: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          /.+@.+\..+/.test(value) || "ข้อมูลไม่ถูกต้อง / Please fill Email",
      ],
      // && this.fn_checkSpecialChar(value)
      requiredusername: [
        (value) =>
          (/^(?=.*[a-z])/.test(value) &&
            value.length >= 6 &&
            this.fn_checkusername(value)) ||
          "ตัวอักษรอย่างน้อย 6 ตัว และห้ามมีอักขระพิเศษ / Must have at least 6 characters",
        (value) => !!value || "โปรดระบุข้อมูล.",
      ],
      requiredpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          (/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value) &&
            this.fn_checkSpecialChar_(value)) ||
          "ต้องมีตัวเลข และตัวอักษรภาษาอังกฤษ อย่างน้อย 1 ตัว ความยาวอย่างน้อย 8 / Must have at least 8 characters",
      ],
      requiredconfirmpassword: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.checkpass(value) || "รหัสผ่านไม่ตรงกัน / Passwords do not match",
      ],
      requiredname: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) => this.checkname(value) || "ข้อมูลไม่ถูกต้อง / Invalid data",
      ],
      requirednameeng: [
        (value) => !!value || "โปรดระบุข้อมูล.",
        (value) =>
          this.checknameeng(value) || "ข้อมูลไม่ถูกต้อง / Invalid data",
      ],
      // birthdaydate: null,
      date: null,
      menu: false,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
      dataLoginDefault: "dataLoginDefault",
      dataAccountActive: "dataAccountActive",
      dataUsername: "dataUsername",
      dataAccountId: "dataAccountId",
    }),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    typename_thError() {
      const errors = [];
      if (!this.$v.typename_th.$dirty) return errors;
      !this.$v.typename_th.required &&
        errors.push(
          "โปรดระบุคำนำหน้าชื่อ(ภาษาไทย) / Please fill Title Name (TH)"
        );
      return errors;
    },
    firstnamethError() {
      const errors = [];
      if (!this.$v.firstnameth.$dirty) return errors;
      !this.$v.firstnameth.required &&
        errors.push("โปรดระบุชื่อ(ภาษาไทย) / Please fill First Name (TH)");
      return errors;
    },
    lastnamethError() {
      const errors = [];
      if (!this.$v.lastnameth.$dirty) return errors;
      !this.$v.lastnameth.required &&
        errors.push("โปรดระบุนามสกุล(ภาษาไทย) / Please fill Last Name (TH)");
      return errors;
    },
    typename_engError() {
      const errors = [];
      if (!this.$v.typename_eng.$dirty) return errors;
      !this.$v.typename_eng.required &&
        errors.push(
          "โปรดระบุคำนำหน้าชื่อ(ภาษาอังกฤษ) / Please fill Title Name (Eng)"
        );
      return errors;
    },
    firstnameengError() {
      const errors = [];
      if (!this.$v.firstnameeng.$dirty) return errors;
      !this.$v.firstnameeng.required &&
        errors.push("โปรดระบุชื่อ(ภาษาอังกฤษ) / Please fill First Name (Eng)");
      return errors;
    },
    lastnameengError() {
      const errors = [];
      if (!this.$v.lastnameeng.$dirty) return errors;
      !this.$v.lastnameeng.required &&
        errors.push("โปรดระบุนามสกุล(ภาษอังกฤษ) / Please fill Last Name (Eng)");
      return errors;
    },
    typecradError() {
      const errors = [];
      if (!this.$v.typecrad.$dirty) return errors;
      !this.$v.typecrad.required &&
        errors.push("โปรดระบุชนิดบัตร / Please fill Type Card");
      return errors;
    },
    typecrad_numError() {
      const errors = [];
      if (!this.$v.typecrad_num.$dirty) return errors;
      !this.$v.typecrad_num.required &&
        errors.push("โปรดระบุข้อมูล / Please fill Type Card Number");
      return errors;
    },
    id_card_numError() {
      const errors = [];
      if (!this.$v.id_card_num.$dirty) return errors;
      !this.$v.id_card_num.required &&
        errors.push("โปรดระบุข้อมูล / Please fill Type Card Number");
      return errors;
    },
    emailError() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required &&
        errors.push("โปรดระบุอีเมล / Please fill Email");
      return errors;
    },
    phonenumberError() {
      const errors = [];
      if (!this.$v.phonenumber.$dirty) return errors;
      !this.$v.phonenumber.required &&
        errors.push("โปรดระบุเบอร์โทรศัพท์ / Please fill Mobile No.");
      return errors;
    },
    dateError() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required &&
        errors.push("โปรดระบุข้อมูลวันเกิด / Please fill Date of Birth");
      return errors;
    },
    usernameError() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required &&
        errors.push("โปรดระบุข้อมูลชื่อผู้ใช้ / Please fill Username");
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required &&
        errors.push(
          "โปรดระบุรหัสผ่าน / Please fill Password / Must have at least 8 characters"
        );
      return errors;
    },
    confirmpasswordError() {
      const errors = [];
      if (!this.$v.confirmpassword.$dirty) return errors;
      !this.$v.confirmpassword.required &&
        errors.push(
          "โปรดยืนยันรหัสผ่าน / Please fill Password / Must have at least 8 characters"
        );
      return errors;
    },
    darkMode() {
      return this.$vuetify.theme.dark
        ? "color: #000000 !important;"
        : "color: #005799 !important;";
    },
  },
  methods: {
    fn_goback(){
      this.$router.push("/moph/loginwithid");      
    },
    async fn_checkid() {
      // this.opendialogcheckidcardmohpromt = true;
      if (this.id_card_num === "") {
        Toast.fire({
          icon: "error",
          title: "กรุณากรอกเลขบัตรประจำตัวประชาชน",
        });
      } else {
        this.createprogress = true;
        let payload = {
          cid: this.id_card_num,
        };
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth);
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_MED + "/api/moph/check/cid",
            payload,
            { headers: { Authorization: auth.code } }
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              this.opendialogcheckidcardmohpromt = true;
            } else {
              this.fn_check_whitelist();
            }
          })
          .catch((error) => {
            this.createprogress = false;
            // Toast.fire({
            //   icon: "error",
            //   title: error,
            // });
            console.log(error);
            this.fn_check_whitelist();
          });
      }
    },
    async fn_check_whitelist() {
      let payload = {
        cid: this.id_card_num,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_MED + "/api/moph/check/whitelist",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.opendialogcheckconsentcardmohpromt = true;
            this.typename_th = response.data.data.account_title_th;
            this.firstnameth = response.data.data.first_name_th;
            this.lastnameth = response.data.data.last_name_th;
            this.typecrad_num = this.id_card_num;
            this.phonenumber = response.data.data.mobile_no;
            this.date = this.fn_date(response.data.data.birth_date);
            this.fn_checktypenameth(response.data.data.account_title_th);
          } else {
            // this.opendialogcheckidcardmohpromt = true;
            this.e1 = 2;
            this.typecrad_num = this.id_card_num;
            // this.fn_check_whitelist();
          }
        })
        .catch((error) => {
          this.createprogress = false;
          // Toast.fire({
          //   icon: "error",
          //   title: error,
          // });
          console.log(error);
          this.e1 = 2;
          this.typecrad_num = this.id_card_num;
        });
    },
    fn_gotologin() {
      this.$router.push("/moph/loginwithid");
    },
    async fn_log_consent() {
      console.log("lllllll");
      this.opendialogcheckconsentcardmohpromt = false;
      this.createprogress = true;
      let payload = {
        cid: this.id_card_num,
        consent_action: "whitelist",
        consent_status: "Y",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth",auth);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_MED + "/api/moph/register/consent",
          payload
          ,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.e1 = 2;
            this.createprogress = false;
          } else {
            this.createprogress = false;
          }
        })
        .catch((error) => {
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    fn_date(item) {
      let date = item.split("");
      let birthdayis =
        date[0] +
        date[1] +
        date[2] +
        date[3] +
        "-" +
        date[4] +
        date[5] +
        "-" +
        date[6] +
        date[7];
      return birthdayis;
    },
    fn_checktypenameth() {
      if (this.typename_th === "นาย") {
        this.typename_eng = "Mr";
      } else if (this.typename_th === "นาง") {
        this.typename_eng = "Mrs";
      } else if (this.typename_th === "นางสาว") {
        this.typename_eng = "Miss";
      }
    },
    fn_checktypenameeng() {
      if (this.typename_eng === "Mr") {
        this.typename_th = "นาย";
      } else if (this.typename_eng === "Mrs") {
        this.typename_th = "นาง";
      } else if (this.typename_eng === "Miss") {
        this.typename_th = "นางสาว";
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    checkpass(value) {
      if (value === this.password) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkSpecialChar(value) {
      let allow = true;
      let specialChar = [
        " ",
        "!",
        "#",
        "^",
        "&",
        "(",
        ")",
        "+",
        "$",
        "%",
        "<",
        ">",
        ":",
        "*",
        "?",
        "\\",
        "|",
        "/",
        '"',
        "'",
      ];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      // console.log("allow", allow);
      return allow;
    },
    fn_checkSpecialChar_(value) {
      let allow = true;
      let specialChar = [" ", '"', "'"];

      for (let index = 0; index < specialChar.length; index++) {
        const element = specialChar[index];
        if (value.includes(element)) {
          allow = false;
          break;
        }
      }
      this.allowcheckpassword = allow;
      console.log("allow", allow);
      return allow;
    },
    fn_checkidcard(value) {
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkphonenumber(value) {
      const pattern = /^0[0-9]{8,9}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    checkname(value) {
      const pattern = /^[ก-๏\s]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_checkusername(value) {
      console.log("value fn_checkusername", value);
      // const pattern = /^[a-z0-9]+$/;
      const pattern = /^[a-z0-9]*$/;
      //const pattern =  /^(?=.*[a-z])(?=.*[0-9]){8,}/;
      if (pattern.test(value) == true) {
        console.log("checkusername true");
        this.allowcheckuser = true;
        console.log(this.allowcheckuser);
        return true;
      } else {
        console.log("checkusername false");
        this.allowcheckuser = false;
        console.log(this.allowcheckuser);
        return false;
      }
    },
    checknameeng(value) {
      const pattern = /^[a-zA-Z\s]+$/;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_cleardata() {
      this.typename_th = "";
      this.firstnameth = "";
      this.lastnameth = "";
      this.typename_eng = "";
      this.firstnameeng = "";
      this.lastnameeng = "";
      this.typecrad = "";
      this.typecrad_num = "";
      this.email = "";
      this.phonenumber = "";
      this.date = "";
      this.username = "";
      this.password = "";
    },
    fn_checksent() {
      this.opendialogcheckconsentcardmohpromt = true;
    },
    fn_checksent_register() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.opendialogcheckconsentcardmohpromt_register = true;
      }
    },
    fn_tolowercase() {
      this.username = this.username.toLowerCase();
    },
    fn_consent_register_appove() {
      console.log("lllllll");
      this.opendialogcheckconsentcardmohpromt = false;
      this.createprogress = true;
      let payload = {
        cid: this.id_card_num,
        consent_action: "register",
        consent_status: "Y",
      };
      console.log("payload", payload);
      // let auth = await gbfGenerate.generateToken();
      // console.log("auth",auth);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_MED + "/api/moph/register/consent",
          payload
          // ,{headers: { Authorization: auth.code },}
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.createprogress = false;
            this.fn_register();
          } else {
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    async fn_register() {
      console.log("==เข้าอันนี้==");
      this.opendialogcheckconsentcardmohpromt_register = false;
      this.$v.$touch();
      // let allowcheckusername = this.fn_checkusername();
      if (!this.$v.$invalid) {
        if(this.password !== this.confirmpassword){
          Toast.fire({
            icon: "error",
              title: "รหัสผ่านไม่ตรงกัน กรุณายืนยันรหัสผ่านอีกครั้ง",
            });
          this.password = '';
          this.confirmpassword = '';
        }else if(this.allowcheckuser == false){
          Toast.fire({
            icon: "error",
              title: "รูปแบบชื่อผู้ใช้งานไม่ถูกต้อง",
            });
            // this.username = '';
        }else if(this.allowcheckpassword == false){
          Toast.fire({
            icon: "error",
              title: "รูปแบบรหัสผ่านไม่ถูกต้อง",
            });
        }else{
      // console.log("fn_checkusername",this.fn_checkusername);
      this.createprogress = true;
      let payload = {
        account_title_th: this.typename_th,
        first_name_th: this.firstnameth,
        last_name_th: this.lastnameth,
        account_title_eng: this.typename_eng,
        first_name_eng: this.firstnameeng,
        last_name_eng: this.lastnameeng,
        id_card_type: "idcard",
        id_card_num: this.typecrad_num,
        email: this.email,
        mobile_no: this.phonenumber,
        birth_date: this.date === null ? "" : this.date,
        username: this.username,
        password: this.password,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_MED + "api/moph/register/oneid",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            this.e1 = 3;
            // this.createprogress = false;
            this.fn_gotologin(this.typecrad_num, this.password);
            // console.log("response ok", response);
            // Toast.fire({
            //   icon: "success",
            //   title: this.$t("register.regissuccess"),
            // });
            // this.checkregistersuccess = true;
            // this.fn_cleardata();
            // this.$router.push("/login");
          } else {
            this.createprogress = false;
            console.log("response notok", response);
            console.log("response notok", response.data.errorMessage);
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.createprogress = false;
          Toast.fire({
            icon: "error",
            title: error.response.data.errorMessage,
          });
          console.log(error);
        });
      }
      }
    },
    async fn_gotologin(idcard, password) {
      this.loaddataprogress = true;
      VueCookies.set("id_card_num",idcard)
      VueCookies.set("check_mohpromt", true);
      VueCookies.set("check_btn_login_new", true);
      let payload;
      console.log("check_button_login**", this.check_button_login);
      console.log("login_test");
      payload = {
        username: idcard,
        password: password,
        login_type: "id_card",
      };
      var encodeduserpass_ = btoa(JSON.stringify(payload));
      payload = {
        data: encodeduserpass_,
        // // decode กลับ
        // var actual = JSON.parse(atob(encodeduserpass))
        // console.log("actual",actual);
      };

      this.$store
        .dispatch("authorize_login_nooneid", payload)
        .then((msg) => {
          // console.log(msg);
          this.loading = false;
          if (msg.status === "Access Granted") {
            // ส่ง event ไปยัง Google Analytics
            this.$gtag.event('login', {
              event_category: "idcard",
            });

            this.loaddataprogress = false;
            this.$router.push("/mydrive");
            console.log("this.check_button_login", this.check_button_login);
            // if(this.check_memory == false){
            //   let userid = localStorage.getItem("user_id")
            //   let token = VueCookies.get('token')
            //   sessionStorage.setItem("user_id", userid)
            //   sessionStorage.setItem("checklogin", false)
            //   sessionStorage.setItem("token", token, 60 * 60 * 24)
            //   sessionStorage.setItem("token_auth", true, 60 * 60 * 24)
            //   VueCookies.remove('token')
            //   VueCookies.remove('token_auth')
            //   localStorage.removeItem("user_id")
            //   VueCookies.set("checklogin", false, 60 * 60 * 24);
            //   VueCookies.remove('user_en')
            //   // VueCookies.set("SessionExpired", false);
            //   // VueCookies.set("test", false);
            // }else{
            VueCookies.set("checklogin", true, 60 * 60 * 24);
            VueCookies.set("user_en", encodeduserpass_, 60 * 60 * 24);
            // }
            console.log(
              "this.dataLoginDefault",
              this.dataLoginDefault,
              this.dataLoginDefault["account_category"]
            );

            // if (this.dataLoginDefault !== "") {
            //   console.log("เข้า if");
            //   if (this.dataLoginDefault["account_category"] === "Business") {
            //     console.log(this.dataLoginDefault["account_category"]);
            //     this.loginbusiness_default();
            //   }
            // }
            // if ("goto" in this.$route.query) {
            //   this.loginbusiness(
            //     this.$route.query.goto,
            //     this.$route.query.taxid
            //   );
            // } else {
            //   this.$router.push("/mydrive");
            // }
          } else if (msg.code === "LN001") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t(
                "landingpage.notiloginandregister.incorrectusernameorpassword"
              ),
            });
            this.loaddataprogress = false;
          } else if (msg.code === "ER401") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t(
                "landingpage.notiloginandregister.incorrectusernameorpassword"
              ),
            });
            this.loaddataprogress = false;
          } else if (msg.msg === "ER401: Authorization is wrong") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t(
                "landingpage.notiloginandregister.incorrectusernameorpassword"
              ),
            });
            this.loaddataprogress = false;
          } else if (msg.code === "ER001") {
            Toast.fire({
              icon: "error",
              // title: "Username หรือ Password ไม่ถูกต้อง"
              title: this.$t(
                "landingpage.notiloginandregister.emptyusernameorpassword"
              ),
            });
            this.loaddataprogress = false;
          } else {
            Toast.fire({
              icon: "error",
              // title: msg.msg
              title: this.$t("toast.cannotconnectonebox"),
            });
            this.loaddataprogress = false;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            // title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ"
            title: this.$t("toast.cannotconnectonebox"),
          });
          this.loaddataprogress = false;
          this.$router.push("/moph/loginwithid");
        });
    },
  },
  mounted() {
    const darkmode = localStorage.getItem("darkmode");
    if (darkmode) {
      if (darkmode === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("darkmode", this.$vuetify.theme.dark.toString());
    }
  },
};
</script>
<style>
#thiscontainer_register_mohpromt {
  height: calc(116vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.v-input input {
  max-height: 24px;
}
</style>
